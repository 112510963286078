import React, { Component } from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import HeroBannerHeader from "../components/global/heroBannerHeader"
import { StaticQuery, graphql } from "gatsby"
import ProductGrid from "../components/product-page/productGrid"
import Instagram from "../components/global/instagram"

class Products extends Component {
  state = {}
  render() {
    return (
      <Layout noHeaderInLayout={false} makeFooterSticky={false}>
        <SEO title="products" />

        <StaticQuery
          query={graphql`
            query {
              headerHeroImage: file(
                relativePath: { eq: "product-hero-banner.png" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 3840) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => (
            <HeroBannerHeader
              data={data}
              text={{ primaryTitle: "really into lips", secondaryTitle: "" }}
            />
          )}
        />
        <ProductGrid />
        {/* <Instagram /> */}
      </Layout>
    )
  }
}

export default Products
