import React from "react"
import coldSoreCream from "../images/product-and-package/cold-sore-cream-updated.jpg"
import intensiveMoisturiser from "../images/product-and-package/inm.jpg"
import lipBrilliance from "../images/product-and-package/lb.jpg"
import medPlus from "../images/product-and-package/mp.jpg"
import medPlusStick from "../images/product-and-package/mp-stick.jpg"
import moisturiseCherry from "../images/product-and-package/mc.jpg"
import reliefCream from "../images/product-and-package/relief-cream-2.jpg"
import ultra from "../images/product-and-package/ul.jpg"
import infusionsNourish from "../images/product-and-package/lip-infusions-nourish.jpg"
import infusionsHydration from "../images/product-and-package/lip-infusions-hydration.jpg"
import infusionsSoothing from "../images/product-and-package/lip-infusions-soothing.jpg"
import infusionsRestore from "../images/product-and-package/lip-infusions-restore.jpg"

export const products = [
  {
    productImg: intensiveMoisturiser,
    productTitle: "Intensive Moisturiser.",
    productDesc:
      "Blistex Intensive Moisturiser is infused with a blend of rich moisturisers like Shea Butter to condition, Camphor to soothe and Allantoin to smooth. It moisturises deep down to intensely rehydrate your lips.",
    productLink: "/intensive-moisturiser",
    disclaimer: "",
  },
  {
    productImg: moisturiseCherry,
    productTitle: "Intensive Moisturiser Cherry.",
    productDesc:
      "Blistex Intensive Moisturiser Cherry is infused with a blend of rich moisturisers like Shea Butter to condition and Allantoin to smooth. It moisturises deep down to intensely rehydrate your lips, with a hint of cherry.",
    productLink: "/intensive-moisturiser-cherry",
    disclaimer: "",
  },
  {
    productImg: medPlus,
    productTitle: "MedPlus Jar.",
    productDesc:
      "Blistex MedPlus Jar is perfect when lips need some TLC. Thanks to conditioning Cocoa Butter and cooling Menthol, it helps to restore lips back to a healthy appearance. Even when they’re at their most distressed.",
    productLink: "/medplus",
    disclaimer: "",
  },
  {
    productImg: medPlusStick,
    productTitle: "MedPlus Stick.",
    productDesc:
      "Blistex MedPlus® special combination of ingredients, such as Menthol, Camphor, Thymol and Beeswax, provides immediate cooling & soothing action and long-lasting moisturization for dry lips. Even when they are at their most distressed.",
    productLink: "/medplus-stick",
    disclaimer: "",
  },
  {
    productImg: infusionsRestore,
    productTitle: "Lip Infusions - Restore",
    productDesc:
      "Blistex Lip Infusions Restore has an advanced lip care formula infused with a blend of ingredients that restores lips’ moisture and leaves them feeling naturally soft and smooth again.",
    productLink: "/lip-infusions-restore",
    disclaimer: "",
  },
  {
    productImg: infusionsHydration,
    productTitle: "Lip Infusions - Hydration",
    productDesc:
      "Blistex Lip Infusions Hydration has a long-lasting lip care formula that provides perfectly balanced hydration to drench your lips in moisture without a heavy, greasy feel and is clinically shown to moisturise for up to 24 hours.",
    productLink: "/lip-infusions-hydration",
    disclaimer: "",
  },
  {
    productImg: infusionsSoothing,
    productTitle: "Lip Infusions - Soothing",
    productDesc:
      "Blistex Lip Infusions Soothing has an advanced lip care formula that is gentle on your lips but tough on dryness, to soothe irritated lips and keep them soft, smooth and conditioned with maximum comfort.",
    productLink: "/lip-infusions-soothing",
    disclaimer: "",
  },
  {
    productImg: infusionsNourish,
    productTitle: "Lip Infusions - Nourish",
    productDesc:
      "Blistex Lip Infusions Nourish has advanced lip care formula that delivers concentrated nourishment for luxuriously smooth lips. Infused with a blend of nutrient-enriched botanical oils and Vitamin E to soften without leaving a greasy feel.",
    productLink: "/lip-infusions-nourish",
    disclaimer: "",
  },
  // {
  //   productImg: lipBrilliance,
  //   productTitle: "Lip Brilliance.",
  //   productDesc:
  //     "Blistex Lip Brilliance gives your lips a rosy blush whilst enriching them with Silk Extracts for soness, Grape Seed Oil to condition and Hyaluronic Filling Spheres to boost their natural fullness, so they look healthy, full and fabulous.",
  //   productLink: "/lip-brilliance",
  //   disclaimer: "",
  // },
  {
    productImg: ultra,
    productTitle: "Ultra SPF 50+",
    productDesc:
      "Blistex Ultra SPF 50+ provides very high sun protection to help defend your lips from UVA and UVB rays. Infused with antioxidant vitamins C & E, it helps seal in moisture to keep them hydrated, whilst protecting them from the sun.",
    productLink: "/ultra-lip-balm",
    disclaimer: "",
  },
  {
    productImg: reliefCream,
    productTitle: "Blistex Relief Cream.",
    productDesc:
      "Blistex Relief Cream has a soothing and protective formula to relieve cracked, chapped and sore lips leaving them feeling soft again. it also helps relieve the discomfort of occasional cold sores on the lips.",
    outOfStock: (
      <>
        <p className="out-of-stock-banner">
          This item is currently out of stock.
        </p>
      </>
    ),

    disclaimer: (
      <>
        <p>
          <strong>
            Blistex Relief Cream is a medicine for chapped lips. Always read the
            label.
          </strong>
        </p>
      </>
    ),
  },
  {
    productImg: coldSoreCream,
    productTitle: "Cold Sore Cream.",
    productDesc:
      "Cold Sore Cream is a clinically proven treatment for the early stages of recurrent cold sores on the lips. it contains Docosanol which works differently to other treatments in the early stages of a cold sore.",
    productLink: "/cold-sore-cream",
    disclaimer: (
      <>
        <p>
          <strong>
            Blistex Cold Sore Cream contains docosanol. Always read the label.
          </strong>
        </p>
      </>
    ),
  },

]
