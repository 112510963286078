import React, { Component } from "react"
import { Link } from "gatsby"
import { products } from '../../data/products';
class ProductGrid extends Component {
  render() {

    this.items = products.map((item, key) => (
      <div className="product-grid__item" key={key}>
        {item.productLink ? (
          <Link to={item.productLink + "/"}>
            <img src={item.productImg} alt={item.productTitle} />
            <div className="product-grid__item-text">
              <div className="product-grid__title-wrapper">
                <h3>{item.productTitle}</h3>
              </div>
              <p>{item.productDesc}</p>
              {item.outOfStock}
              {item.disclaimer}
            </div>
          </Link>
        ) : (
          <div>
            <img src={item.productImg} alt={item.productTitle} />
            <div className="product-grid__item-text">
              <div className="product-grid__title-wrapper">
                <h3>{item.productTitle}</h3>
              </div>
              <p>{item.productDesc}</p> 
              {item.outOfStock}
              {item.disclaimer}
            </div>
          </div>
        )}
      </div>
    ))
    return (
      <section className="product-grid">
        <div className="product-grid__title-container">
          <div className="product-grid__title">
            <h2 className="vertical-heading">the Blistex range</h2>
          </div>
        </div>
        <div className="product-grid__container">{this.items}</div>
      </section>
    )
  }
}

export default ProductGrid
