import React, { Component } from "react"
import Img from "gatsby-image"

class HeroBannerHeader extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <section className="hero-banner-header">
        <Img fluid={this.props.data.headerHeroImage.childImageSharp.fluid} />
        <div className="hero-banner-header__title">
          <h1>{this.props.text.primaryTitle}</h1>

          {this.props.text.secondaryTitle !== "" && (
            <h2>{this.props.text.secondaryTitle}</h2>
          )}
        </div>
      </section>
    )
  }
}

export default HeroBannerHeader
